import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';

import { Table } from 'react-bootstrap';


const TimeHeader = () => {

  return Array(12).fill(null).map((_, index)=><td style={{padding: '6px', display:'flex', height: '45px'}}>
    <div style={{flexGrow: '1', lineHeight: '1.1em', fontSize: '16px', fontWeight: '600'}}>
      {`${index+1}교시`}<br/>
      <span style={{fontSize: '12px', opacity: '50%'}}>{`(${index + 9}:00)`}</span>
    </div>
  </td>)
}

const DayColumn = inject("schedule")(observer(({ schedule, day, dayIndex }) =>  {

  const timeList = schedule.getTime();
  const items = []

  const timeToggle = (e) => {
    if (!(schedule.selected && schedule.selected[1] === 'select')) return;
    //console.log(schedule.selected[0], [e.target.getAttribute('col'), e.target.getAttribute('row')])
    schedule.toggleTime(schedule.selected[0], [e.target.getAttribute('col'), e.target.getAttribute('row')])
  }

  let lastCell = null;

  const cell = (index) => {
    index++;
    let find = timeList.find(x=>x[0] == dayIndex && x[1] == index)
    if (find) {
      const div = (<td key={index} style={{padding: '7px', backgroundColor: find[3], display:'flex', height: '45px', overflow: 'visible'}} row={index} col={dayIndex} onClick={e => timeToggle(e)}>
        <div style={{flexGrow: '1', lineHeight: '1.1em', fontSize: '14px', fontWeight: '600', pointerEvents: 'none'}}>
          {(lastCell != find[2]) ? find[2] : ''}
        </div>
      </td>)
      lastCell = find[2];
      return div;
    } else {
      lastCell = null;
      return (<td key={index} style={{ padding: '7px', display:'flex', height: '45px'}} row={index} col={dayIndex} onClick={e => timeToggle(e)}></td>);
    }
  }
  
  let column = Array(12).fill(null).map((_, index) => {
    return cell(index)
  })
  return (
    <tr style={{flex: '1 1 0px'}}>
      <td style={{ padding: '7px', display:'flex', fontWeight: '700'}}><div style={{flexGrow: '1'}}>{`${day}요일`}</div></td>
      {column}
    </tr>
  )
}));


const TimeTable = inject("schedule")(observer(({ schedule }) => {

  return (
    <Table>
      <tbody style={{display: 'flex'}}>
        <tr style={{width: '5em'}}>
            <td style={{ padding: '7px', display:'flex'}}><div style={{flexGrow: '1'}}>&nbsp;</div></td>
            {TimeHeader()}
        </tr>
        {['월', '화', '수', '목', '금', '토'].map((x, i)=><DayColumn day={x} dayIndex={i+1}/>)}
      </tbody>
    </Table>
  );
    
  (
    <Table bordered className="noselect text-center" style={{tableLayout: 'fixed', fontSize: '13px'}}>
        <thead>
            <tr style={{fontSize: '16px', fontWeight: '700'}}>
                <th></th>
                <th>월</th>
                <th>화</th>
                <th>수</th>
                <th>목</th>
                <th>금</th>
                <th>토</th>
            </tr>
        </thead>
        <tbody>
            <TimeRow timeList={timeList} index={1} />
            <TimeRow timeList={timeList} index={2} />
            <TimeRow timeList={timeList} index={3} />
            <TimeRow timeList={timeList} index={4} />
            <TimeRow timeList={timeList} index={5} />
            <TimeRow timeList={timeList} index={6} />
            <TimeRow timeList={timeList} index={7} />
            <TimeRow timeList={timeList} index={8} />
            <TimeRow timeList={timeList} index={9} />
            <TimeRow timeList={timeList} index={10} />
            <TimeRow timeList={timeList} index={11} />
            <TimeRow timeList={timeList} index={12} />
        </tbody>
    </Table>
  )

}))
  
export default TimeTable;